@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
display: none;
}

body{
    @apply font-[Prod-normal] bg-[#040214];
}

.homebutton {
    @apply rounded-full bg-white px-7 font-normal text-black lg:text-lg;
}

.evoxhighlight{
    @apply text-[#0060ff]
}

.middleshadow{
    box-shadow: 0px 4px 20px 20px rgb(0 96 255 / 0.05);
}

.active{
    @apply text-white underline underline-offset-8;
}

.buttonSelect {
    @apply w-fit text-center rounded-full border-4 border-[#0060ff] uppercase px-6 py-3 duration-100 ease-linear hover:bg-[#0060ff];
}

.fadeEffect {
    @apply !bg-clip-text text-center font-[prod-bold] text-[156px] text-transparent [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]
}
